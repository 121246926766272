import { MutableRefObject } from "react";
import Modal, { modalRefProps } from "..";
import Spinner from "../../Spinner";

interface ModalProps {
  modalRef: MutableRefObject<modalRefProps | null>;
}
export const LoadingModal = ({ modalRef }: ModalProps) => {
  return (
    <Modal ref={modalRef} title="Estamos processando sua imagem" width="500px">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 16,
          marginTop: 16,
        }}
      >
        <Spinner />
        <p>Por favor, aguarde enquanto processamos sua imagem.</p>
      </div>
    </Modal>
  );
};
