import { httpClient } from "../httpClient";

interface ValidateResponse {

}

class ValidateService {
  async validate(userId: string, imageBase64: string) {
    const {data} = await httpClient.post<ValidateResponse>(`user/${userId}`, {
      imageBase64,
    });
    return data;
  }
}
export default new ValidateService();
