import { MutableRefObject, ReactNode, useState } from "react";
import Modal, { modalRefProps } from "..";
import { CheckCircle } from "lucide-react";

interface ModalProps extends DataProps {
  modalRef: MutableRefObject<modalRefProps | null>;
  icon?: ReactNode;
  valuesIsIntenalProps?: boolean;
}

interface DataProps {
  title?: string;
  message?: string;
}

export const Alert = ({
  modalRef,
  title,
  icon,
  message,
  valuesIsIntenalProps,
}: ModalProps) => {
  const [data, setData] = useState<DataProps>();
  console.log(data)
  return (
    <Modal
      ref={modalRef}
      title={
        title || (valuesIsIntenalProps && data?.title) || "Imagem enviada!"
      }
      width="500px"
      getValues={setData}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 16,
          marginTop: 16,
        }}
      >
        {icon}
        <div>
          {message ||
            (valuesIsIntenalProps && data?.message) ||
            "Sua imagem foi enviada com sucesso! Retorne ao app e faça login."}
        </div>
      </div>
    </Modal>
  );
};
