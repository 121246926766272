import PropTypes from "prop-types";
import "./styles.css";

export default function Spinner({ size = 32, color = "#F3125B " }) {
  return (
    <div style={{ padding: 16 }}>
      <div id="spinner" style={{ fontSize: `${size}px`, color }} />
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
};
