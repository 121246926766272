import { useEffect, useRef, useState } from "react";
import {
  CallbackCamera,
  ErrorPictureResponse,
  SelfieCameraType,
  SelfieCameraTypes,
  SuccessPictureResponse,
  SupportPictureResponse,
  UnicoCheckBuilder,
  UnicoThemeBuilder,
} from "unico-webframe";
import validateService from "../../services/validateService";
import { useParams } from "react-router-dom";
import { modalRefProps } from "../../components/modal";

export function useHomeController() {
  const modalRefLoading = useRef<modalRefProps | null>(null);
  const modalRefSuccess = useRef<modalRefProps | null>(null);
  const modalRefFailed = useRef<modalRefProps | null>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const autoClose = process.env.REACT_APP_AUTOCLOSE === "true";
  const urlPathModels = `${window.location.protocol}//${window.location.host}/models`;

  const unicoTheme = new UnicoThemeBuilder()
    .setColorSilhouetteSuccess("#69c62f")
    .setColorSilhouetteError("#D50000")
    .setColorSilhouetteNeutral("#fcfcfc")
    .setBackgroundColor("#e9e9e9")
    .setColorText("#F3125B")
    .setBackgroundColorComponents("#F3125B")
    .setColorTextComponents("#dff1f5")
    .setBackgroundColorButtons("#F3125B")
    .setColorTextButtons("#dff1f5")
    .setBackgroundColorBoxMessage("#fff")
    .setColorTextBoxMessage("#F3125B")
    .setHtmlPopupLoading(
      `<div style="position: absolute; top: 45%; right: 50%; transform: translate(50%, -50%); z-index: 10; text-align: center;">Carregandooooo...</div>`
    )
    .build();
  const unicoCamera = new UnicoCheckBuilder()
    .setTheme(unicoTheme)
    .setModelsPath(urlPathModels)
    .setResourceDirectory("/resources")
    .build();

  const sdkCallbacks: CallbackCamera = {
    on: {
      success: async function (obj: SuccessPictureResponse) {
        console.log(obj);
        if (id)
          try {
            modalRefLoading.current?.open();
            await validateService.validate(id, obj.encrypted);

            modalRefLoading.current?.close();
            modalRefSuccess.current?.open();
          } catch (error) {
            modalRefFailed.current?.open({ message: error });

            console.log(error);
          }
        autoClose && window.close();
      },
      error: function (error: ErrorPictureResponse) {
        modalRefFailed.current?.open({
          title: error.code,
          message: error.message,
        });
        console.log(error);
      },
      support: function (error: SupportPictureResponse) {
        console.log(error);
      },
    },
  };

  const prepareSelfieCamera = async (
    jsonPath: string,
    cameraType: SelfieCameraType
  ) => {
    setLoading(true);

    const { open } = await unicoCamera.prepareSelfieCamera(
      jsonPath,
      cameraType
    );
    open(sdkCallbacks);

    setLoading(false);
  };
  const json = process.env.REACT_APP_SERVICE_INFO || "";

  useEffect(() => {
    prepareSelfieCamera(json, SelfieCameraTypes.NORMAL);
  }, []);

  return {
    loading,
    modalRef: {
      success: modalRefSuccess,
      failed: modalRefFailed,
      loading: modalRefLoading,
    },
  };
}
