import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { createPortal } from "react-dom";

import "./styles.css";

export interface modalRefProps {
  open: (data?: any) => void;
  close: () => void;
  contains?: (target: Node) => boolean;
}

interface ModalProps {
  children?: React.ReactNode;
  title?: string;
  width?: string;
  getValues?: (data?: any) => void;
}

const ComponenteModal: React.ForwardRefRenderFunction<
  modalRefProps | null,
  ModalProps
> = ({ getValues, children, title, width, ...rest }: ModalProps, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialData, setInitialData] = useState(null);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setIsOpen(true);
      if (data) setInitialData(data);
    },
    close: () => {
      setIsOpen(false);
      setInitialData(null);
      getValues && getValues(null);
    },
    getInitialData: () => initialData,
  }));

  useEffect(() => {
    getValues && getValues(initialData);
  }, [initialData]);

  if (!isOpen) return null;

  const closeModal = () => {
    if (ref && "current" in ref && ref.current) {
      ref.current.close();
    }
  };

  return createPortal(
    <div onClick={closeModal}>
      <div className="Container">
        <div
          className="Content"
          {...rest}
          onClick={(e: any) => e.stopPropagation()}
          style={{ maxWidth: width ? width : "700px" }}
        >
          <div className="Title">{title}</div>
          <div>{children}</div>
        </div>
      </div>
    </div>,
    document.body
  );
};

const Modal = forwardRef(ComponenteModal);

export default Modal;
