import "./styles.css";
import "../../styles/global.css";
import logoCredliber from "../../assets/logo_branco.png";
import { useHomeController } from "./useHomeController";
import { LoadingModal } from "../../components/modal/LoadingModal";
import { Alert } from "../../components/modal/Alert";
import { CheckCircle, CircleX } from "lucide-react";
import Spinner from "../../components/Spinner";

export default function Home() {
  const { loading, modalRef } = useHomeController();
  return (
    <>
      <LoadingModal modalRef={modalRef.loading} />

      <Alert
        icon={<CheckCircle size={64} color="#f3125b" />}
        modalRef={modalRef.success}
      />

      <Alert
        title="Erro ao processar imagem"
        icon={<CircleX size={64} color="#f3125b" />}
        modalRef={modalRef.failed}
        valuesIsIntenalProps
      />

      <div className="main-container">
        <div className="homeContainer">
          {loading && (
            <div className="loadingContainer">
              <Spinner size={24} color="white" />
              <p>carregando...</p>
            </div>
          )}
          <div className="imageContainer">
            <img src={logoCredliber} />
          </div>
        </div>
      </div>
    </>
  );
}
